@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Lao&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Lao", sans-serif;
}

:root {
  --color-212529: #212529;
  --color-ffd333: #ffd333;
  --color-47ad24: #47ad24;
  --color-e52e2e: #e52e2e;
  --color-828599: #828599;
  --color-3d464d: #ed464d;
  --color-c3d4da: #ced4da;
}

.green {
  color: var(--color-47ad24);
  font-size: 14px;
  font-family: Roboto !important;
}

.red {
  color: var(--color-212529);
  font-size: 14px;
  font-family: Roboto !important;
}

.quill {
  background-color: white;
}

.form-control {
  box-shadow: none;
  border-color: var(--color-c3d4da);
}

.title {
  font: 28px;
  font-family: Roboto !important;
  color: var(--color-212529);
}

.sub-title {
  font: 24px;
  font-family: Roboto !important;
  color: var(--color-212529);
}

.desc {
  font: 14px;
  font-family: Roboto !important;
  color: var(--color-828599);
}

/* src/App.css */
.responsive-table {
  overflow-x: auto;
}



@media screen and (max-width: 767px) {
  .responsive-table .ant-table-thead {
    display: none;
  }

  .responsive-table .ant-table-tbody > tr > td {
    display: block;
    text-align: right;
  }

  .responsive-table .ant-table-tbody > tr {
    margin-bottom: 16px;
  }
}
